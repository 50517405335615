var Partners_SettingsController = {
    searchable: false,
    init: function () {
        var self = this;
        $('#Partners_Form_Settings_Informations #billing_same_address').on('change update', function () {
            var checked = $(this).is(':checked');
            if (checked)
                $('.same-address').attr('disabled', 'disabled');
            else
                $('.same-address').removeAttr('disabled');
        }).trigger('update');

    }
};