var Vignerons_CustomersController = {
    init: function () {
        var self = this;

        $('body').on('submit', 'form.search', function (e) {
            e.preventDefault();
            var form = $(this);
            $.ajax({
                data: form.serialize(),
                success: function (d) {
                    $('.table-container').replaceWith(d);
                }
            });
        });


        // STEP Revendeur
        $('body').on('change','#Vignerons_Form_Customers_Import input',function(){
            var form = $(this).parents('form');
            form.addClass('loading');
            form.submit();
        });
    }
};