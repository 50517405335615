(function ($, window, document, undefined)
{
    // Create the defaults once
    var pluginName = "confirmHelper",
        defaults = {
            dataAttr: 'data-confirm'
        };

    // The actual plugin constructor
    function Plugin(element, options)
    {
        this.element = element;
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    Plugin.prototype = {
        init: function ()
        {
            var self = this;

            $(this.element).on('click', '[' + this.settings.dataAttr + ']', function (e) {
                var element = $(this);

                if (element.hasClass('btn-disabled')) {
                    e.preventDefault();
                    return;
                }

                if (element.hasClass('button')) {
                    element.addClass('btn-disabled');
                }

                if (!confirm(element.attr(self.settings.dataAttr))) {
                    e.preventDefault();
                    if (element.hasClass('button')) {
                        element.removeClass('btn-disabled');
                    }
                    return;
                }
                if(element.hasClass('popinify')){
                    e.preventDefault();
                    Popin.open(element.attr('href'));
                }
            });
        }
    };

    $.fn[ pluginName ] = function (options)
    {
        return this.each(function ()
        {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
