var Vignerons_PromotionsController = {
    init : function(){
        var self = this;
        this.calcul();

        $("select.refresh-data").on('change',function(){
            self.calcul();
        });

    },
    calcul : function(){
        var remise = parseFloat($("#remise").val());
        var nb_bouteilles_min = parseInt($("#nb_bouteilles_min").val());

        $("table .line-cuvee").each(function(i,cuvee){
            var price = parseFloat($(cuvee).find('.col-price').data('value'));
            var tplPricePromo = $(cuvee).find('.col-price-promo').data('tpl');
            var tplCA = $(cuvee).find('.col-vente').data('tpl');

            if(price != 0){
                if(remise != '' &&  (remise - parseFloat( remise ) + 1) >= 0 && remise > 0){
                    var newPrice = price - (price * (remise/100));
                    tplPricePromo = tplPricePromo.replace('%s',newPrice);
                    $(cuvee).find('.col-price-promo input').val(tplPricePromo);

                    if(nb_bouteilles_min != '' && (nb_bouteilles_min - parseFloat( nb_bouteilles_min ) + 1) >= 0 && nb_bouteilles_min > 0){
                        tplCA = tplCA.replace('%s',newPrice*nb_bouteilles_min);
                        $(cuvee).find('.col-vente input').val(tplCA);
                    }
                }
            }

        });
    }
}
