var Subscribers_ProfilController = {
    init: function () {
      $('#Subscribers_Form_Profil_Gouts .form-element .dropdown .list').jScrollPane({
            autoReinitialise: true
        });

        // Lors d'un clic sur un filtre
        $(document).on('click', '.filters .filter', function (e) {
            e.preventDefault();
            var target = $(this).data('target');

            // si c'est un range
            if ($('#' + target + '-min').length > 0) {
                $('#' + target + '-min').val($('#' + target + '-min').data('min'));
                $('#' + target + '-max').val($('#' + target + '-min').data('max'));
            } else {
                // si c'est une checkbox classique
                $('#' + target + '-' + $(this).data('val')).removeAttr('checked');
            }
            $('#Subscribers_Form_Profil_Gouts').trigger('submit');
        });

        $('#Subscribers_Form_Profil_Gouts .form-element.choose-picture .list label').on('change update', function () {
            if ($(this).find('input').is(':checked')) {
                $(this).addClass('checked');
            }
            else {
                $(this).removeClass('checked');
            }
        }).trigger('update');
    }
};
