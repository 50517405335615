var Vignerons_CustomersGroupsController = {
    init: function () {
        var self = this;
        Vignerons_CustomersGroupsController.ajaxSearch();
    },
    ajaxSearch:function(){

        $('body').on('click', '.table-list-customer  td.col-is_selected input', function () {
            var form = $(this).parents('form');
            $.ajax({
                url: '/vignerons/customers-groups/save-selection/',
                data: form.serializeArray()
            });
        });

        $('body').on('click', 'a.start-search', function (e) {
            e.preventDefault();

            var form = $(this).parents('form');
            $.ajax({
                data: form.serialize(),
                success: function (d) {
                    $('.table-container').replaceWith(d);
                    CssRadioCheckbox.parseAll();
                }
            });
        });
        $('body').on('click', 'a.select-all', function (e) {
            e.preventDefault();
            $("#check_all").prop('checked','checked');
            var form = $(this).parents('form');
            form.find('.col-is_selected input[type=checkbox]').prop('checked','checked');
            $.ajax({
                url: '/vignerons/customers-groups/select-all/',
                data: form.serialize(),
                success: function (d) {
                }
            });
        });

        $('body').on('submit', '#Vignerons_Form_CustomersGroups,#Vignerons_Form_MailsCampaign_Cible', function (e) {
            if ($(':focus').attr('id') == 'q') {
                e.preventDefault();
                $('a.start-search').trigger('click');
            }
        });

        $('body').on('click','.check-all',function(e){
            var form = $(this).parents('form');
            if($(this).is(':checked')){
                form.find('.col-is_selected input[type=checkbox]').prop('checked','checked');
                $.ajax({
                    url: '/vignerons/customers-groups/select-all/',
                    data: form.serialize(),
                    success: function (d) {
                    }
                });
            } else {
                form.find('.col-is_selected input[type=checkbox]').removeProp('checked');
                $.ajax({
                    url: '/vignerons/customers-groups/save-selection/',
                    data: form.serializeArray()
                });
            }
        });
    }
};