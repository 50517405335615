var Subscribers_ParcoursController = {
    init: function () {
        $('#Subscribers_Form_Share .form-element .dropdown .list').jScrollPane({
            autoReinitialise: true
        });

        $('body').on('click', '.social-networks div', function (e) {
            e.preventDefault();
            $("#Subscribers_Form_Share #type").val($(this).data('type'));
            $('.social-networks div').removeClass('active');
            $(this).addClass('active');
            if ($(this).hasClass('email')) {
                $('.form-group.emails').slideDown();
            } else {
                $('.form-group.emails').slideUp();
            }

        });

        $('body').on('click', '#Subscribers_Form_Share input[type="submit"]:not(".main-submit")', function (e) {
            e.preventDefault();
            $(this).parents('.form-element').removeClass('open');
        });

        $('body').on('submit', '#Subscribers_Form_Share', function (e) {
            e.preventDefault();
            var $this = $(this);

            var val = [];
            $this.find('input[name="favorites[]"]:checked').each(function (i) {
                val[i] = $(this).val();
            });

            if (val.length == 0)
                return alert($this.data('empty-msg'));

            var idUser = $this.find('#id_user').val();
            var userType = $this.data('user-type');
            var param = ($('#dataType').val() == 'activities') ? 'a' : 'd';
            var url = $this.data('base-url') + '?' + userType + '=' + idUser + '&' + param + '=' + val.join();
            var urlTwitter = $this.data('twitter-url') + encodeURIComponent('?' + userType + '=' + idUser + '&' + param + '=' + val.join());

            switch ($("#Subscribers_Form_Share #type").val()) {
                case 'email':
                    Popin.open($this.attr('action'), $(this).serializeArray(), 'POST');
                    break;
                case 'facebook':
                    FB.ui(
                        {
                            method: 'feed',
                            name: $this.attr('data-title'),
                            link: url,
                            picture: $this.attr('data-picture'),
                            description: $this.attr('data-description')
                        }, function (response) {
                            if (response && response.post_id) {
                                Popin.close();
                            } else {
                            }
                        });
                    break;
                case 'twitter':
                    Twitter.open(urlTwitter);
                    break;
            }
        });

        $('body').on('click', '#form-cancel-reservation-submit', function (e) {
            $("#form-cancel-reservation").submit();
        })
        $('body').on('submit', '#form-cancel-reservation', function (e) {
            e.preventDefault();
            var form = $(this);
            form.closest('.dialog-content').addClass('ajaxify-container loading')
            $("#form-cancel-reservation-submit").prop('disabled', true).addClass('btn-disabled');

            $.ajax({
                'url': $(this).attr('action'),
                'data': $(this).serialize(),
                'type': 'POST',
                'dataType': "json",
            }).done(function (result) {
                form.closest('.dialog-content').removeClass('ajaxify-container loading')
                $("#form-cancel-reservation-submit").prop('disabled', false).removeClass('btn-disabled');

                if (!result.success) {
                    if (result.form !== undefined) {
                        form = $(result.form);
                        $("#form-cancel-reservation").replaceWith(form);
                    } else {
                        if (result.message !== undefined) {
                            alert(result.message);
                        }
                    }

                    if (result.warning !== undefined) {
                        alert(result.warning);
                        window.location.reload();
                    }
                    return;
                }

                window.location.reload();
            }).always(function () {
                form.closest('.dialog-content').removeClass('ajaxify-container loading')
                $("#form-cancel-reservation-submit").prop('disabled', false).removeClass('btn-disabled');
            })
        })
    }
};
