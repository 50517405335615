var Partners_ProfilController = {
    searchable: false,
    init: function () {
        $('#Partners_Form_Profil_Informations #billing_same_address').on('change update', function () {
            var checked = $(this).is(':checked');
            if (checked)
                $('.same-address').attr('disabled', 'disabled');
            else
                $('.same-address').removeAttr('disabled');
        }).trigger('update');

        $('.order-btn #order').on('change',function(){
            $('.container-filters #order').val( $(this).val() );
            $("#Partners_Form_Profil_ReservationsFilters").submit();
        });

        Subscribers_ParcoursController.init();
    }
};