var Vignerons_CampaignsController = {
    init: function () {
        var self = this;
        Vignerons_CustomersGroupsController.ajaxSearch();

        var form = $('#Vignerons_Form_MailsCampaign_Contenu');
        if (form.size()) {
            form.find('select#cuveeadded').change(function () {
                var value = $(this).val();
                if (value != '') {
                    form.find('#domainscuvees-' + value).attr('checked', 'checked');
                    form.find('#cuvee-' + value).addClass('active');
                }
                $(this).val('');
            });
            $('.cuvee a.remove').click(function (e) {
                e.preventDefault();
                $(this).parent().removeClass('active');
                var id = $(this).parent().attr('id').substring(6);
                $('#domainscuvees-' + id).removeAttr('checked');
            });
            form.find('select#activityadded').change(function () {
                var value = $(this).val();
                if (value != '') {
                    form.find('#domainsactivities-' + value).attr('checked', 'checked');
                    form.find('#activity-' + value).addClass('active');
                }
                $(this).val('');
            });
            $('.activity a.remove').click(function (e) {
                e.preventDefault();
                $(this).parent().removeClass('active');
                var id = $(this).parent().attr('id').substring(6);
                $('#domainsactivities-' + id).removeAttr('checked');
            });
        }

        var formDate = $('#Vignerons_Form_MailsCampaign_Dates');
        if (formDate.size()) {
            formDate.find('input.radio').on('click update',function(){
                if($('#send_type-delay').is(':checked')){
                    $('.form-group.send_date').show();
                }
                else{
                    $('.form-group.send_date').hide();
                }
            }).trigger('update');
        }
    }
};