var Vignerons_SettingsController = {
    searchable: false,
    init: function () {
        var self = this;

        // SUBSTEP GENERAL - LOCALISATION
        $(".field-linked-data").each(function (i, elem) {
            var $field = $($(elem).data('linked-field'));

            if ($field.val() == null || $field.val() == '') {
                $field.attr('disabled', 'disabled');
            }
        });
        $(".field-linked-data").on('change', function () {
            var linkedField = $($(this).data('linked-field'));
            $.post('/vignerons/settings_ajax/get-data', {type: linkedField.data('table'), id: $(this).val(), field: $(this).data('field')}, function (data) {
                linkedField.empty();
                var options = {};

                if (data.result_code == 'error'){
                    linkedField.select2(options);
                    linkedField.trigger('change');
                    return;
                }

                linkedField.attr('disabled', null);
                linkedField.append($('<option>', {value: '', text: ''}));
                $.each(data.options, function (i, opt) {
                    linkedField.append($('<option>', {value: opt.value, text: opt.key}));
                });
                linkedField.trigger('change');

                if (linkedField.data('max-selection') != null) {
                    options = {maximumSelectionLength: linkedField.data('max-selection')};
                }
                linkedField.select2(options);
            }, 'json');
        });

//        CodePostalAutocomplete.autocompleteVille($("#postal_code"), $("#ville"));

        // SUBSTEP GENERAL - ADDRESS
        $("#ville,#postal_code,#address").on('change', function () {
            var addr = $("#address").val();
            self.searchable = false;
            if (addr != '') {
                self.searchable = true;
            }
            if ($("#postal_code").val().length == $("#postal_code").attr('maxlength')) {
                addr += ' ' + $("#postal_code").val();
            }
            if ($("#country").val() != '') {
                addr += ' ,' + $("#country").val();
            }
            $("#address_complete").val(addr).change();
        });


        // STEP PHOTOS
        if ($('.fileupload').length > 0)
            $('.fileupload').each(function() {
              $(this).fileupload({
                dropZone: $(this).parent(),
                url: $(this).data('url'),
                dataType: 'json',
                beforeSend : function(xhr, opts){
                      $('.ajaxify-container').addClass('loading');
//                    var q = $("#msg-replace").val();
//                    if(!$(opts.fileInput).parents('.fileinput-button').find('.thumbnail').hasClass('empty') && !window.confirm(q))  {
//                        xhr.abort();
//                    }
                },
                done: function (e, data) {
                    $('.ajaxify-container').removeClass('loading');
                    if(data._response.result.result == 'error'){
                        if (data._response.result.errors) {
                            alert(data._response.result.errors);
                        } else {
                            alert( $("#error-upload").val());
                        }
                    } else {
                        var message = data._response.result.message;
                        if (!message) {
                            message = $("#upload-pending").val();
                        }
                        alert(message);
                        if (data._response.result.type == 'url_photo_profil_new') {
                            $(this).next('img').attr('src', data._response.result.url);
                        } else {
                            $(this).parents('.fileinput-button').find('.thumbnail').data('id',data._response.result.id);
                            $(this).parents('.fileinput-button').find('.thumbnail').css({
                                'background-image': 'url(' + data._response.result.url + ')'
                            }).removeClass('empty').attr('href',data._response.result.href);
                        }

                        if(typeof data._response.result.href != 'undefined'){
                            $(this).fileupload('option','url',data._response.result.href );
                        }
                    }
                },
                fail : function(e,data){
                    $('.ajaxify-container').removeClass('loading');
                    alert( $("#error-upload").val());
                },
                progressall: function (e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                }
              });
            });

        $('.thumbnail .delete').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            if (confirm($(this).data('msg'))){
                $.ajax({
                    'url': $(this).attr('href')+$(this).parents('.thumbnail').data('id'), 'type': 'post', cache: false, 'success': function (data) {
                        if(data == 'ok'){
                            $this.parents('.thumbnail').removeAttr('style').addClass('empty');
                        }
                    }
                });
            }
        });

        // INFOS
        $('.step-infos #billing_same_address').on('change update', function () {
            var checked = $(this).is(':checked');
            if (checked){
                $('.same-address').attr('disabled', 'disabled');
            }
            else{
                $('.same-address').removeAttr('disabled');
            }
        }).trigger('update');


        $('.substep-notifications #same_email').on('change update', function () {
            var checked = $(this).is(':checked');
            if (checked){
                $('#notification_email').attr('readonly', 'readonly');
            }
            else{
                $('#notification_email').removeAttr('readonly');
            }
        }).trigger('update');

        $('.substep-notifications input[name=notification_sms]').on('change update', function () {
            var checked = $(this).val();
            if (checked == '0' && $(this).is(':checked')){
                $('#tel_number_alert').attr('readonly', 'readonly');
            }
            else{
                $('#tel_number_alert').removeAttr('readonly');
            }
        }).trigger('update');


        Vignerons_Settings_revendeursController.init();
        Vignerons_SettingsController_Planning.init();
        Vignerons_SettingsController_OnlinePayment.init();
        Vignerons_SettingsController_Widget.init();
    }
}

var Vignerons_Settings_revendeursController = {
    init: function () {
        // STEP Revendeur
        $('body').on('change', '#Vignerons_Form_Revendeurs_Import input', function () {
            var form = $(this).parents('form');
            form.addClass('loading');
            form.submit();
        });
    }
}
