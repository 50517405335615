if (!String.prototype.ucfirst) {
    /**
     * @see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/String/Trim
     * IE9+
     * @returns {string}
     */
    String.prototype.ucfirst = function () {
        var f = this.charAt(0)
            .toUpperCase();
        return f + this.substr(1);
    };
}
