var Vignerons_CalendarWizardsController = {
    init: function () {

        // Etape Working Days
        var formWorkingDays = $('#Vignerons_Form_Calendar_WizardA_Step3, #Vignerons_Form_Planning_StepWorkingDays');
        if (formWorkingDays.size()) {
            var inputs = formWorkingDays.find('#working_days-element input');
            inputs.on('click update', function () {
                if ($(this).is(':checked')) {
                    $(this).parent().addClass('checked');
                }
                else {
                    $(this).parent().removeClass('checked');
                }
            }).trigger('update');
            inputs.each(function () {
                var span = $('<span/>');
                span.append('<i class="icon-bin-2-black"/>');
                span.append('<i class="icon-plus-black"/>');
                span.appendTo($(this).parent());
            });
        }

        var slots = $('div.form-slot-line');
        slots.each(function () {
            var slot = $(this);
            //if (slot.prev().hasClass('form-slot-line') || slot.hasClass('show-btn-add')) {
            if (slot.find('select').val() == '')
                slot.hide();
            else {
                slot.show();
            }
            var a = $('<a class="icon-minus"/>');
            if (slot.find('select[disabled]').size() == 0) {
                slot.append(a);
                a.click(function () {
                    slot.hide();
                    slot.find('select').val('').trigger('change');
                })
            }
            if (slot.hasClass('disablable')) {
                slot.append(a);
                if (slot.prev('input.input-hidden').val() == '1') {
                    a.toggleClass('icon-minus');
                    a.toggleClass('icon-plus');
                    slot.toggleClass('disabled');
                }
                a.click(function () {
                    a.toggleClass('icon-minus');
                    a.toggleClass('icon-plus');
                    slot.toggleClass('disabled');
                    slot.prev('input.input-hidden').val(slot.hasClass('disabled') ? '1' : '');
                });

            }
            //}
            //else {
            //    slot.show();
            //}
        });
        if (slots.size()) {
            $('a.add-slot').click(function (e) {
                e.preventDefault();
                var target = $(this).attr('target');
                $(target).filter(':hidden').first().show();
                $('select.select2:not(.select2-hidden-accessible)').select2();
            });
        }
    }
};